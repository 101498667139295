import { styled } from "@mui/material";
import { Link } from "gatsby";
import { breakpoint } from "../../components/styled-components/Breakpoints/breakpoints";

export const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 50px;
  gap: 40px;
  text-align: left;
  align-items: center;
  height: 100%;
  width: 100%;

  ${breakpoint(
    "xlarge-",
    `
    padding: 30px;
  `
  )}

  ${breakpoint(
    "medium-",
    `
    padding: 20px;
  `
  )}
`;

export const FAQList = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 70%;
  justify-content: center;

  ${breakpoint(
    "xlarge-",
    `
    width: 80%;
  `
  )}

  ${breakpoint(
    "medium-",
    `
    width: 90%;
  `
  )}

${breakpoint(
    "small-",
    `
    gap: 10px;
  `
  )}
`;

export const ListItemWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  color: white;
`;

export const QuestionHeader = styled("h2")`
  color: #ffb310;
  text-align: left;
  margin: 0;
  font-size: 4rem;

  ${breakpoint(
    "small-",
    `
    font-size: 18px;
    font-weight: 500;
  `
  )}
`;

export const LinkContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  color: white;
  width: fit-content;

  h2 {
    background-color: black;
    font-size: 15px;
    font-weight: 400;
    padding: 10px;
    margin: 0;
    width: fit-content;
    transition: transform 0.4s;

    &:hover {
      color: #ffb310;
      text-decoration: underline;
      transform: scale(1.03);
    }
  }
`;

export const StyledAnchor = styled("a")`
  color: white;
  width: fit-content;

  h2 {
    background-color: black;
    font-size: 15px;
    font-weight: 400;
    padding: 10px;
    margin: 0;
    width: fit-content;
    transition: transform 0.4s;

    &:hover {
      color: #ffb310;
      text-decoration: underline;
      transform: scale(1.03);
    }
  }
`;

export const CameraList = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
  margin-bottom: 20px;

  ${breakpoint(
    "medium-",
    `
    flex-direction: column;
  `
  )}
`;

export const Column = styled("div")`
  display: flex;
  flex-direction: column;

  ${breakpoint(
    "medium-",
    `
    justify-content: center;
  `
  )}
`;

export const CameraItem = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  flex: 1;

  ${breakpoint(
    "medium-",
    `
    padding: 30px 0px;
    flex-direction: column;
  `
  )}
`;

export const PhotoContainer = styled("div")`
  min-width: 20%;
  height: 100%;

  ${breakpoint(
    "medium-",
    `
    justify-content: center
  `
  )}
`;

export const InfoContainer = styled("div")`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  line-height: 20px;
  gap: 3px;

  ${breakpoint(
    "medium-",
    `
    width: 90%;
    text-align: center;
  `
  )}
`;

export const CameraHeader = styled("span")`
  font-size: 20px;
  color: #ffb310;
  font-weight: 300;

  ${breakpoint(
    "small-",
    `
    font-size: 18px;
    font-weight: 500;
  `
  )}
`;
export const CameraUseCases = styled("span")`
  font-size: 18px;
  color: white;
  font-weight: 300;

  ${breakpoint(
    "small-",
    `
    font-size: 16px;
  `
  )}
`;

export const CameraFeatures = styled("span")`
  font-size: 16px;
  color: white;
  font-weight: 200;

  ${breakpoint(
    "small-",
    `
    font-size: 14px;
  `
  )}
`;

export const CameraImage = styled("img")`
  height: 100px;
  width: 100px;
  border: 1px solid #ffb310;
  border-radius: 100px;
  padding: 10px;
  object-fit: contain;

  ${breakpoint(
    "medium-",
    `
    height: 80px;
     width: 80px;
  `
  )}
`;

export const CameraSectionHeader = styled("span")`
  color: #ffb310;
  font-size: 4rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.5;

  ${breakpoint(
    "small-",
    `
    font-size: 18px;
    font-weight: 500;

  `
  )};
`;

export const CameraSectionSubHeader = styled("span")`
  color: #ffb310;
  font-size: 3rem;
  margin: 20px 0px;
  font-weight: 300;
  align-self: center;
  text-align: center;

  ${breakpoint(
    "small-",
    `
    font-size: 16px;
    font-weight: 400;

  `
  )}
`;

export const FAQText = styled("span")`
  font-size: 20px;

  ${breakpoint(
    "small-",
    `
    font-size: 16px;

  `
  )}
`;

export const YoutubeContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: relative;
  flex-wrap: auto;
  justify-content: flex-start;
  margin: 20px 0 0;

  ${breakpoint(
    "medium-",
    `
    flex-direction: column;
  `
  )}
`;

export const LinkSectionHeader = styled("span")`
  color: #ffb310;
  font-size: 3rem;
  font-weight: 300;
  align-self: center;
  text-align: center;

  ${breakpoint(
    "small-",
    `
    font-size: 16px;
    font-weight: 400;

  `
  )}
`;

export const YoutubeIframe = styled("iframe")`
  height: 560 * 9/16;
  border: none;

  ${breakpoint(
    "medium-",
    `
    width: 560px;
    height: 315px;
  `
  )}
`;

export const RoadmapGraphic = styled("div")`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;

  ${breakpoint(
    "medium-",
    `
    padding: 0px;
    width: 90%;
    margin: 0 auto;
  `
  )}
`;

export const RoadmapImage = styled("img")`
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
  max-width: min(100%, 1200px);
`;
